<template>
<router-view :key="$route.fullPath" />
<!-- <div class="router">
  <transition :name="fade" enter-active-class="fadeIn-enter fadeIn-enter-active" leave-active-class="fadeIn-enter fadeIn-leave-active">
    <router-view :key="$route.fullPath" />
  </transition>
</div> -->
</template>

<script>
export default {
  data() {
    return {
      fade: ""
    }
  },
  watch: {
    $route(to, from) {
      //将不想有过渡动画效果的部分路由写进判断条件中
      if (to.path !== '/' && to.path !== '/' && to.path !== '/' || from.path !== '/' && from.path !== '/' && from.path !== '/') {
        //如果to索引大于from索引,判断为前进状态,反之则为后退状态
        if (to.meta.index < from.meta.index) {
          //设置动画名称
          this.transitionName = 'slide-left';
        } else if (to.meta.index > from.meta.index) {
          this.transitionName = 'slide-right';
        } else if (to.meta.index == 99) {
          this.transitionName = ""
        }
      } else {
        this.transitionName = ""
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.router {
  height: 100%;
  box-sizing: border-box;
}

.fadeIn-enter {
  // 组件显示前的状态
  opacity: 1;
}

.fadeIn-enter-active {
  // 组件显示时的状态
  transition: all 0.3s linear;
}

.fadeIn-leave-active {
  // 组件消失时候的状态
  // transition: all 0.3s linear;
  // opacity: 0;
  display: none;
}
</style>

<style>

</style>
